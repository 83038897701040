import axios from 'axios';

const fetchPersonalTimeline = async (userId, userAccessToken) => {
    try {
        const response = await axios.get(`https://api.onestories.life/user/${userId}/personalTimeline`, {
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
            },
        });

        // Se la timeline esiste, restituiamo solo l'ID
        if (response.data && response.data.timelineId) {
            return response.data.timelineId;
        }

        // Se la timeline non esiste, restituiamo un valore indicativo (opzionale)
        return null; // O un altro valore di errore, come 'timeline non trovata'
    } catch (error) {
        throw new Error('Errore nel recupero della timeline personale: ' + error.message);
    }
};

export default fetchPersonalTimeline;