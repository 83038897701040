import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';  // Per ottenere il parametro dell'URL
import TimelinePreview from '../TimelinePreview/TimelinePreview'; // Importa il componente di anteprima della timeline

const SharedTimelineView = () => {
    const { token } = useParams(); // Estrai il token dalla URL
    const [loading, setLoading] = useState(true); // Stato per gestire il loading
    const [error, setError] = useState(null); // Stato per eventuali errori

    // Renderizza il componente TimelinePreview con il timelineId
    return <TimelinePreview sharedTimelineToken={token} />;
};

export default SharedTimelineView;