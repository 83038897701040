import { PublicClientApplication } from '@azure/msal-browser';

// Configurazione MSAL per l'app React
export const msalConfig = {
  auth: {
    clientId: '46a0fa0c-6781-4dca-948d-be04ab8cc3fb',
    authority: 'https://onestoriesb2c.b2clogin.com/onestoriesb2c.onmicrosoft.com/B2C_1_signuplogin',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    knownAuthorities: ['onestoriesb2c.b2clogin.com'],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      logLevel: 'info',
      piiLoggingEnabled: false,
    },
  },
};

// Crea l'istanza di MSAL
export const msalInstance = new PublicClientApplication(msalConfig);

// Funzione per gestire i redirect
export const initializeMsal = async () => {
  try {
    await msalInstance.initialize();
    const redirectResponse = await msalInstance.handleRedirectPromise();
  } catch (error) {
    console.error('Error during MSAL initialization:', error);
    throw error; // Lascia che gli errori siano visibili al chiamante
  }
};