import React, { useState, useEffect } from 'react';
import { FaSpinner, FaCheckCircle } from 'react-icons/fa';
import { useMsal } from '@azure/msal-react';
import './CreateMilestone.css';

const CreateMilestone = ({ userAccessToken, timelineId, onSave, generatedSummaryMilestone, initialCreationMethod = 'ai', summaryTimelineId = null}) => {
    const [creationMethod, setCreationMethod] = useState(initialCreationMethod);
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [oneRowSumup, setOneRowSumup] = useState('');
    const [extensiveSumup, setExtensiveSumup] = useState('');
    const [tag, setTag] = useState('Personal');
    const [milestoneUserInput, setMilestoneUserInput] = useState('');
    const [response, setResponse] = useState(null);
    const [saving, setSaving] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupTimeout, setPopupTimeout] = useState(null);

    const { accounts } = useMsal();
    const user = accounts.length > 0 ? accounts[0] : null;
    const userId = user ? user.localAccountId : null;

    useEffect(() => {
        // Se viene passato generatedSummaryMilestone, popola i campi
        if (generatedSummaryMilestone) {
            setTitle(generatedSummaryMilestone.title || '');
            setDate(generatedSummaryMilestone.date || '');
            setOneRowSumup(generatedSummaryMilestone.oneRowSumup || '');
            setExtensiveSumup(generatedSummaryMilestone.extensiveSumup || '');
            setTag(generatedSummaryMilestone.tag || 'Personal');
            setCreationMethod('manual'); // Imposta il metodo di creazione su 'manual'
        } else {
            setCreationMethod(initialCreationMethod); // Mantieni il valore predefinito di 'ai' se non viene passato generatedSummaryMilestone
        }
    }, [generatedSummaryMilestone, initialCreationMethod]); // Dipendenze da generatedSummaryMilestone e initialCreationMethod

    const handleGenerateMilestone = (e) => {
        e.preventDefault();
        setSaving(true);

        const newMilestone = { milestoneUserInput };

        fetch('https://api.onestories.life/generate-milestone', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userAccessToken}` },
            body: JSON.stringify(newMilestone),
        })
            .then((res) => res.json())
            .then((data) => {
                setResponse(data);
                setTitle(data.title || '');
                setDate(data.date || '');
                setOneRowSumup(data.oneRowSumup || '');
                setExtensiveSumup(data.extensiveSumup || '');
                setTag(data.tag || 'Personal');
            })
            .catch((error) => console.error('Error generating milestone:', error))
            .finally(() => setSaving(false));
    };

    const handleSave = () => {
        if (!userId) {
            alert('User ID not found.');
            return;
        }

        const milestoneToSave = {
            userId,
            timelineId,
            summaryTimelineId,
            title,
            date,
            oneRowSumup,
            extensiveSumup,
            tag,
            creationMethod,
        };

        fetch('https://api.onestories.life/milestone-create', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userAccessToken}` },
            body: JSON.stringify(milestoneToSave),
        })
            .then((res) => res.json())
            .then((data) => {
                setPopupVisible(true);
                if (popupTimeout) {
                    clearTimeout(popupTimeout);
                }
                const timeoutId = setTimeout(() => {
                    setPopupVisible(false);
                }, 3000);
                setPopupTimeout(timeoutId);

                if (onSave) onSave({ ...milestoneToSave, id: data.id });
            })
            .catch((error) => console.error('Error saving milestone:', error));
    };

    return (
        <div className="create-milestone-popup-overlay">
            <div className="create-milestone-popup">
                <h2>Crea Milestone</h2>

                {/* Metodo di creazione */}
                <div className="creation-method">
                    <button
                        className={creationMethod === 'ai' ? 'active' : ''}
                        onClick={() => setCreationMethod('ai')}
                    >
                        Genera con l'AI
                    </button>
                    <button
                        className={creationMethod === 'manual' ? 'active' : ''}
                        onClick={() => setCreationMethod('manual')}
                    >
                        Crea Manualmente
                    </button>
                </div>

                {/* Form per creazione manuale */}
                {creationMethod === 'manual' && (
                    <form>
                        <div className="input-group">
                            <label htmlFor="title">Titolo</label>
                            <input
                                id="title"
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="date">Data</label>
                            <input
                                id="date"
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="oneRowSumup">Descrizione breve</label>
                            <input
                                id="oneRowSumup"
                                type="text"
                                value={oneRowSumup}
                                onChange={(e) => setOneRowSumup(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="extensiveSumup">Riassunto del Ricordo</label>
                            <textarea
                                id="extensiveSumup"
                                value={extensiveSumup}
                                onChange={(e) => setExtensiveSumup(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="tag">Tag</label>
                            <select
                                id="tag"
                                value={tag}
                                onChange={(e) => setTag(e.target.value)}
                                required
                            >
                                <option value="Personal">Personale</option>
                                <option value="Work">Lavoro</option>
                                <option value="Education">Formazione</option>
                            </select>
                        </div>
                        <button type="button" onClick={handleSave}>
                            Salva Milestone
                        </button>
                    </form>
                )}

                {/* Form per generazione con AI */}
                {creationMethod === 'ai' && (
                    <form onSubmit={handleGenerateMilestone}>
                        <div className="input-group">
                            <label htmlFor="milestoneUserInput">Descrivi il tuo Ricordo</label>
                            <textarea
                                id="milestoneUserInput"
                                value={milestoneUserInput}
                                onChange={(e) => setMilestoneUserInput(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" disabled={saving}>
                            {saving ? <FaSpinner className="spinner" /> : 'Genera Milestone'}
                        </button>
                    </form>
                )}

                {/* Popup per il successo */}
                {popupVisible && (
                    <div className="popup-success">
                        <FaCheckCircle className="success-icon" />
                    </div>
                )}

                {/* Mostra il form generato con AI se ci sono dati disponibili */}
                {creationMethod === 'ai' && response && (
                    <div className="generated-data">
                        <h3>Milestone Generata</h3>
                        <div className="input-group">
                            <label htmlFor="generatedTitle">Titolo</label>
                            <input
                                id="generatedTitle"
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="generatedDate">Data</label>
                            <input
                                id="generatedDate"
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="generatedOneRowSumup">Descrizione breve</label>
                            <input
                                id="generatedOneRowSumup"
                                type="text"
                                value={oneRowSumup}
                                onChange={(e) => setOneRowSumup(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="generatedExtensiveSumup">Riassunto del Ricordo</label>
                            <textarea
                                id="generatedExtensiveSumup"
                                value={extensiveSumup}
                                onChange={(e) => setExtensiveSumup(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="tag">Categoria</label>
                            <div className="custom-select-wrapper">
                                <select
                                    id="tag"
                                    value={tag}
                                    onChange={(e) => setTag(e.target.value)}
                                    required
                                    className="custom-select"
                                >
                                    <option value="Personal">Personale</option>
                                    <option value="Work">Lavoro</option>
                                    <option value="Education">Formazione</option>
                                </select>
                            </div>
                        </div>
                        <button type="button" onClick={handleSave}>
                            Salva Milestone
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreateMilestone;