import React from 'react';
import './FloatingButtonAdd.css';

const FloatingButtonAdd = ({ onClick }) => {
  return (
    <button className="floating-button-add" onClick={onClick}>
      +
    </button>
  );
};

export default FloatingButtonAdd;