// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance, initializeMsal } from './msalConfig';

const renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  );
};

// Inizializza MSAL e gestisci eventuali errori

await initializeMsal()
  .then(() => {
    renderApp();
  })
  .catch((error) => {
    console.error('Error initializing MSAL:', error);

    // Opzionale: Mostra un messaggio di errore all'utente
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <div style={{ color: 'red', textAlign: 'center', marginTop: '20%' }}>
          <h1>Error initializing application</h1>
          <p>Please try refreshing the page or contact support.</p>
        </div>
      </React.StrictMode>
    );
  });

reportWebVitals();