import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import NavBar from './components/NavBar/NavBar';
import Dashboard from './components/Dashboard/Dashboard';
import Timelines from './components/Timelines/Timelines';
import WelcomeCard from './components/WelcomeCard/WelcomeCard';
import PersonalTimeline from './components/PersonalTimeline/PersonalTimeline';
import SharedTimelineView from './components/SharedTimelineView/SharedTimelineView';
import useAuthentication from './hooks/useAuthentication';
import './App.css';

function App() {
    const [personalTimelineId, setPersonalTimelineId] = useState();
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Stato per gestire il caricamento
    const { login, logout, accounts } = useAuthentication(setUser);

    useEffect(() => {
        // Simula l'attesa per l'autenticazione o recupero dei dati
        const authenticatedUser = accounts.length > 0 ? accounts[0] : null;
        setUser(authenticatedUser);
        setIsLoading(false); // Una volta che i dati sono pronti, settiamo isLoading a false
    }, [accounts]);

    if (isLoading) {
        // Mostriamo una schermata di caricamento mentre stiamo aspettando che `user` sia pronto
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <div className="App">
                <Header />
                <NavBar
                    user={user}
                    login={login}
                    logout={logout}
                />
                <div className="page-content">
                    <Routes>
                        {/* Rotta per la pagina principale */}
                        <Route
                            path="/"
                            element={!user ? <WelcomeCard login={login} /> : <Navigate to="/dashboard" />}
                        />

                        {/* Rotta per la dashboard */}
                        <Route
                            path="/dashboard"
                            element={
                                user ? (
                                    <Dashboard
                                        user={user}
                                    />
                                ) : (
                                    <Navigate to="/" />
                                )
                            }
                        />

                        {/* Rotta per la timeline personale */}
                        <Route
                            path="/my-journey"
                            element={
                                user ? (
                                    <PersonalTimeline
                                        user={user}
                                        personalTimelineId={personalTimelineId}
                                        setPersonalTimelineId={setPersonalTimelineId}
                                    />
                                ) : (
                                    <Navigate to="/" />
                                )
                            }
                        />

                        {/* Rotta per la gestione di tutte le timeline */}
                        <Route
                            path="/timelines"
                            element={
                                user ? (
                                    <Timelines
                                        userAccessToken={user.accessToken}
                                        personalTimelineId={personalTimelineId}
                                    />
                                ) : (
                                    <Navigate to="/" />
                                )
                            }
                        />

                        {/* Rotta per la preview di una timeline condivisa */}
                        <Route
                            path="/timeline/view/:token"
                            element={<SharedTimelineView
                                user={user}
                            />}
                        />

                        {/* Rotta di fallback */}
                        <Route path="*" element={<div>Page not found!</div>} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;