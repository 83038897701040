import React, { useState } from 'react';
import createPersonalTimeline from '../../hooks/createPersonalTimeline';
import './MissingTimelineCard.css'; // Eventuali stili personalizzati

function MissingTimelineCard({ userId, userAccessToken, onTimelineCreated }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleCreateTimeline = async () => {
        setLoading(true);
        setError(null);

        try {
            const timelineId = await createPersonalTimeline(userId, userAccessToken);
            onTimelineCreated(timelineId); // Callback per notificare il genitore
        } catch (err) {
            console.error(err.message);
            setError('Errore durante la creazione della timeline. Riprova.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="missing-timeline-card">
            <h2>Crea la tua timeline personale</h2>
            <p>
                Inizia subito a raccontare la tua storia. Trasforma i tuoi ricordi
                in una timeline che celebra il tuo viaggio personale e abbraccia
                nuove sfide.
            </p>
            {error && <p className="error-message">{error}</p>}
            <button onClick={handleCreateTimeline} disabled={loading}>
                {loading ? 'Creazione in corso...' : 'Inizia'}
            </button>
        </div>
    );
}

export default MissingTimelineCard;