import React, { useState, useEffect } from 'react';
import MilestoneCard from '../MilestoneCard/MilestoneCard';
import CreateMilestone from '../CreateMilestone/CreateMilestone';
import './TimelinePreview.css';
import FloatingButtonAdd from '../FloatingButton/FloatingButtonAdd';
import FloatingButtonRefresh from '../FloatingButton/FloatingButtonRefresh';
import fetchMilestones from '../../hooks/fetchMilestones';
import fetchTimelineDetails from '../../hooks/fetchTimelineDetails';

function TimelinePreview({ userAccessToken, userId, timelineId, sharedTimelineToken, expandedTimeline, setExpandedTimeline }) {
    const [selectedTags, setSelectedTags] = useState({
        Personal: false,
        Work: false,
        Education: false,
    });

    const [milestones, setMilestones] = useState([]);
    const [timelineDetails, setTimelineDetails] = useState([]);
    const [indicatorPosition, setIndicatorPosition] = useState(0);
    const [currentYear, setCurrentYear] = useState(null); // Per l'anno centrale
    const [milestoneCounts, setMilestoneCounts] = useState({});
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [forceRender, setForceRender] = useState(0);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [fetchError, setFetchError] = useState(null);

    const fetchData = async () => {
        if (sharedTimelineToken) {
            setFetchLoading(true);
            setFetchError(null);
            setMilestones(null);

            try {
                const timelineDetailsData = await fetchTimelineDetails(null, null, null, sharedTimelineToken);
                setTimelineDetails(timelineDetailsData);

                const milestonesData = await fetchMilestones(null, null, null, sharedTimelineToken);
                setMilestones(milestonesData);

                // Imposta l'anno iniziale
                if (milestonesData.length > 0) {
                    const firstYear = new Date(milestonesData[0].date).getFullYear();
                    setCurrentYear(firstYear);
                }
            } catch (error) {
                setFetchError(error.message);
            } finally {
                setFetchLoading(false);
            }
        } else if (userAccessToken && userId && timelineId) {
            setFetchLoading(true);
            setFetchError(null);

            try {
                const timelineDetailsData = await fetchTimelineDetails(userId, timelineId, userAccessToken);
                setTimelineDetails(timelineDetailsData);

                const milestonesData = await fetchMilestones(userId, timelineId, userAccessToken);
                setMilestones(milestonesData);

                // Imposta l'anno iniziale
                if (milestonesData.length > 0) {
                    const firstYear = new Date(milestonesData[0].date).getFullYear();
                    setCurrentYear(firstYear);
                }
            } catch (error) {
                setFetchError(error.message);
            } finally {
                setFetchLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [userAccessToken, userId, timelineId, sharedTimelineToken]);

    const handleDeleteMilestone = (id) => {
        setMilestones((prevMilestones) => prevMilestones.filter((milestone) => milestone.id !== id));
        setForceRender((prev) => prev + 1);
    };

    const getYear = (milestone) => new Date(milestone.date).getFullYear();

    const updateMilestoneCounts = (milestones) => {
        const counts = {};
        milestones.forEach((milestone) => {
            const year = getYear(milestone);
            const tag = milestone.tag;

            if (!counts[year]) {
                counts[year] = { Personal: 0, Work: 0, Education: 0 };
            }

            if (tag && counts[year][tag] !== undefined) {
                counts[year][tag]++;
            }
        });
        setMilestoneCounts(counts);
    };

    const filterMilestones = (milestones) => {
        if (!Object.values(selectedTags).includes(true)) return milestones;
        return milestones.filter((milestone) => {
            return (
                (selectedTags.Personal && milestone.tag === 'Personal') ||
                (selectedTags.Work && milestone.tag === 'Work') ||
                (selectedTags.Education && milestone.tag === 'Education')
            );
        });
    };

    const toggleTag = (tag) => {
        setSelectedTags((prevSelectedTags) => ({
            ...prevSelectedTags,
            [tag]: !prevSelectedTags[tag],
        }));
    };

    const [isLoadingComplete, setIsLoadingComplete] = useState(false);

    useEffect(() => {
        if (!fetchLoading) {
            setIsLoadingComplete(true);
            updateMilestoneCounts(milestones); // Aggiorna il conteggio delle milestone quando i dati sono caricati
        }
    }, [fetchLoading, milestones]);

    useEffect(() => {
        const handleScroll = () => {
            const timelineElement = document.querySelector('.timeline-line');
            if (timelineElement) {
                const rect = timelineElement.getBoundingClientRect();
                const position = Math.max(0, Math.min(rect.height, window.innerHeight / 2 - rect.top));
                setIndicatorPosition(position);

                const visibleMilestones = filterMilestones(milestones).filter((milestone) => {
                    const milestoneRect = document.getElementById(milestone.id).getBoundingClientRect();
                    return (
                        milestoneRect.top >= 0 &&
                        milestoneRect.top <= window.innerHeight / 2 &&
                        milestoneRect.bottom >= window.innerHeight / 2
                    );
                });

                if (visibleMilestones.length > 0) {
                    setCurrentYear(getYear(visibleMilestones[Math.floor(visibleMilestones.length / 2)]));
                }
            }
        };

        const timelinePreview = document.querySelector('.timeline-preview');
        if (timelinePreview) {
            timelinePreview.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (timelinePreview) {
                timelinePreview.removeEventListener('scroll', handleScroll);
            }
        };
    }, [milestones, selectedTags]);

    const handleSaveMilestone = (newMilestone) => {
        setIsPopupOpen(false);

        const localMilestone = {
            ...newMilestone,
            goals: [],
            successes: [],
            challenges: [],
        };

        setMilestones((prevMilestones) => {
            const updatedMilestones = [...prevMilestones, localMilestone];
            return updatedMilestones.sort((a, b) => new Date(b.date) - new Date(a.date));
        });
        setForceRender((prev) => prev + 1);
    };

    return (
        <>
            <div className="timeline-preview" key={forceRender}>
                {fetchLoading ? (
                    <div className="loading-container">
                        <div className="bouncing-ball"></div>
                        <div className="bouncing-ball"></div>
                        <div className="bouncing-ball"></div>
                    </div>
                ) : (
                        <>
                            {milestones && milestones.length > 0 ? (
                                <>
                                    <div className="year-sidebar">
                                        {currentYear && (
                                            <div>
                                                <span>{currentYear}</span>
                                                {milestoneCounts[currentYear] && (
                                                    <div className="milestone-counts">
                                                        <div className="milestone personal">
                                                            <span>{milestoneCounts[currentYear].Personal}</span> Personale
                                                        </div>
                                                        <div className="milestone work">
                                                            <span>{milestoneCounts[currentYear].Work}</span> Lavoro
                                                        </div>
                                                        <div className="milestone education">
                                                            <span>{milestoneCounts[currentYear].Education}</span> Formazione
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {expandedTimeline && <p className="timeline-tag">Timeline</p>}
                                    <h3 className="timeline-title">{timelineDetails.title}</h3>
                                    <p className="timeline-description">{timelineDetails.description}</p>
                                    <div className={`timeline-container ${isLoadingComplete ? 'fade-in' : ''}`}>
                                        <div className="filter-container">
                                            <button
                                                className={`filter-btn ${selectedTags.Personal ? 'active' : ''}`}
                                                onClick={() => toggleTag('Personal')}
                                            >
                                                Personal
                                            </button>
                                            <button
                                                className={`filter-btn ${selectedTags.Work ? 'active' : ''}`}
                                                onClick={() => toggleTag('Work')}
                                            >
                                                Work
                                            </button>
                                            <button
                                                className={`filter-btn ${selectedTags.Education ? 'active' : ''}`}
                                                onClick={() => toggleTag('Education')}
                                            >
                                                Education
                                            </button>
                                        </div>

                                        <div className="timeline-line">
                                            <div className="timeline-indicator" style={{ top: `${indicatorPosition}px` }}></div>
                                        </div>

                                        {fetchError ? (
                                            <div className="error-container">
                                                <p>{fetchError}</p>
                                            </div>
                                        ) : (
                                            filterMilestones(milestones).map((milestone, index) => {
                                                const isNewYear = index === 0 || getYear(milestone) !== getYear(milestones[index - 1]);

                                                return (
                                                    <React.Fragment key={milestone.id}>
                                                        {isNewYear && (
                                                            <div className="year-separator">
                                                                <span>{getYear(milestone)}</span>
                                                            </div>
                                                        )}
                                                        <div className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`} id={milestone.id}>
                                                            <MilestoneCard
                                                                milestone={milestone}
                                                                userAccessToken={userAccessToken}
                                                                onDelete={handleDeleteMilestone}
                                                                onTimelineExpandClick={() => { }}
                                                                setExpandedTimeline={setExpandedTimeline}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })
                                        )}
                                    </div>
                                    {!sharedTimelineToken && (
                                        <div className="floating-buttons-container">
                                            <FloatingButtonRefresh onClick={fetchData} />
                                            <FloatingButtonAdd onClick={() => setIsPopupOpen(true)} />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className="no-milestone-image-container">
                                        <img className="no-milestone-image" src="/assets/no-milestones.png" alt="no-milestones"></img>
                                        <p className="no-milestone-text">La tua Timeline &#232; pronta. Crea la tua prima Milestone.</p>
                                    </div>
                                    <div className="floating-buttons-container">
                                        <FloatingButtonAdd onClick={() => setIsPopupOpen(true)} />
                                        </div>
                                </>
                            )}
                    </>
                 )}
            </div>

            {isPopupOpen && (
                <CreateMilestone
                    userAccessToken={userAccessToken}
                    timelineId={timelineId}
                    onClose={() => setIsPopupOpen(false)}
                    onSave={handleSaveMilestone}
                />
            )}
        </>
    );
}

export default TimelinePreview;