import { useState, useEffect } from 'react';
import axios from 'axios';

const useShareShareWithUser = (userId, token) => {  // Aggiungi 'token' come parametro
    const [milestones, setMilestones] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!userId) return; // Evita di eseguire il fetch se userId non � definito

        const fetchMilestones = async () => {
            setLoading(true);
            try {
                // Aggiungi l'header Authorization con il token Bearer
                const response = await axios.get(
                    `https://api.onestories.life/user/${userId}/milestones-details`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Usa il token nel formato Bearer
                        }
                    }
                );
                setMilestones(response.data);
            } catch (err) {
                setError('Unable to load milestones');
                console.error('Errore durante il caricamento delle milestones:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchMilestones();
    }, [userId, token]);  // Assicurati di dipendere anche dal 'token'

    return { milestones, loading, error };
};

export default useShareShareWithUser;