import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate per gestire il routing
import './NavBar.css';

const NavBar = ({ setPage, user, login, logout }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate(); // Hook per la navigazione

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleNavigation = (route) => {
        navigate(route); // Naviga alla rotta specificata
    };

    return (
        <nav className="navbar">
            <ul className="left">
                {user && (
                    <>
                        <li>
                            <button
                                onClick={() => handleNavigation('/dashboard')}
                                className="nav-button"
                            >
                                Dashboard
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => handleNavigation('/my-journey')}
                                className="nav-button"
                            >
                                Timeline Personale
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => handleNavigation('/timelines')}
                                className="nav-button"
                            >
                                Timeline Collaborative
                            </button>
                        </li>
                    </>
                )}
            </ul>
            <ul className="right">
                {!user ? (
                    <li>
                        <button onClick={login} className="login-button">
                            Accedi
                        </button>
                    </li>
                ) : (
                    <li className="dropdown">
                        <button
                            className="dropdown-toggle"
                            onClick={toggleMenu}
                        >
                            <span className="user-name">{user.idTokenClaims?.given_name || 'Utente'}</span>
                        </button>
                        {menuOpen && (
                            <ul className="dropdown-menu">
                                <li>
                                    <button onClick={logout} className="logout-button">
                                        Logout
                                    </button>
                                </li>
                            </ul>
                        )}
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default NavBar;