import React, {} from 'react';
import './Header.css';


const Header = () => {

    return (
        <nav className="header">
            <div className="title">
                OneStories
            </div>
        </nav>
    );
};

export default Header;