import axios from 'axios';

const fetchTimelineDetails = async (userId, timelineId, userAccessToken, sharedTimelineToken) => {
    // Se c'� un userAccessToken, devono esserci anche userId e timelineId
    if (userAccessToken) {
        if (!userId || !timelineId) {
            throw new Error('userId and timelineId are required when userAccessToken is provided');
        }
    }
    // Se c'� solo sharedTimelineToken, basta quello
    else if (!sharedTimelineToken) {
        throw new Error('Either userAccessToken or sharedTimelineToken is required');
    }

    try {
        let url;
        let body = {};
        let headers = {};

        // Se c'� sharedTimelineToken, invia il token nel corpo della richiesta (POST)
        if (sharedTimelineToken) {
            url = 'https://api.onestories.life/timeline/shared/view/timeline-details';
            body = { token: sharedTimelineToken };

            // Fai la richiesta POST per sharedTimelineToken
            const response = await axios.post(url, body);
            return response.data; // Restituisci i dettagli della timeline
        }
        // Se c'� userAccessToken, userId e timelineId, fai la chiamata GET per recuperare i dettagli della timeline dell'utente
        else {
            if (!userId || !timelineId || !userAccessToken) {
                throw new Error('userId, timelineId and userAccessToken are required');
            }
            url = `https://api.onestories.life/user/${userId}/timeline/${timelineId}/timeline-details`;
            headers = {
                Authorization: `Bearer ${userAccessToken}`,
            };

            // Fai la richiesta GET per userAccessToken
            const response = await axios.get(url, { headers });
            return response.data; // Restituisci i dettagli della timeline
        }
    } catch (error) {
        console.error('Errore durante il caricamento dei dettagli della timeline:', error);
        throw new Error('Unable to load timeline details');
    }
};

export default fetchTimelineDetails;