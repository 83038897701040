// src/components/WelcomeCard/WelcomeCard.js
import React from 'react';
import './WelcomeCard.css';  // Puoi aggiungere gli stili che preferisci

function WelcomeCard({ login }) {
    return (
        <div className="welcome-card">
            <h2>Benvenuto su OneStories!</h2>
            <p>
                OneStories &#232; un'applicazione che ti permette di costruire e condividere la tua storia personale.
                Crea una timeline dei tuoi momenti pi&#249; importanti, riflettiti sulle tue esperienze e ispirati per il futuro.
            </p>
            <p>
                Per iniziare, effettua il login e inizia a raccontare il tuo percorso unico!
            </p>
            <button onClick={login}>Accedi</button> {/* Pulsante per fare il login o ricaricare la pagina */}
        </div>
    );
}

export default WelcomeCard;