import axios from 'axios';

// Funzione per generare il token di condivisione
const shareCreateLink = async (userAccessToken, userId, timelineId) => {

    if (!userAccessToken || !userId || !timelineId) {
        throw new Error('Invalid parameters for generating share link');
    }

    try {
        // Chiamata API per generare il token di condivisione
        const response = await axios.post(
            `https://api.onestories.life/user/${userId}/timeline/${timelineId}/createShareLink`,
            {}, // Corpo della richiesta (vuoto)
            {
                headers: {
                    'Authorization': `Bearer ${userAccessToken}`, // Usa il token nel formato Bearer
                }
            }
        );

        // Restituisci il token di condivisione
        return response.data.shareToken; // Assumendo che l'API ritorni un campo 'shareToken'
    } catch (error) {
        console.error('Errore durante la generazione del token:', error);
        throw new Error('Unable to generate share token');
    }
};

export default shareCreateLink;