import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = ( { user } ) => {
    const navigate = useNavigate(); // Usa useNavigate invece di useHistory
    console.log(user);
    const test_user = {
        name: 'Giovanni Rossi',
        photo: 'https://via.placeholder.com/100',
        milestones: 8,
        emotionalState: 'positive',
        aiMessage: 'Continua a lavorare sugli obiettivi professionali, ma prenditi anche il tempo per riflettere sul benessere personale.',
    };

    const handleCardClick_PersonalTimeline = () => {
        // Usa navigate per navigare alla pagina "/my-journey"
        navigate('/my-journey');
    };

    const handleCardClick_SharedTimelines = () => {
        // Usa navigate per navigare alla pagina "/my-journey"
        navigate('/timelines');
    };

    return (
        <div className="dashboard-container">
            {/* Sidebar */}
            <div className="sidebar">
                <div className="profile-photo" style={{ backgroundImage: `url(${test_user.photo})` }} />
                <p className="name">{user.idTokenClaims.given_name}</p>
                <p className="surname">{user.idTokenClaims.family_name}</p>
                <p>{test_user.milestones} milestone completate</p>
                <p>{test_user.emotionalState === 'positive' ? 'Buon periodo della tua vita!' : 'Fase di riflessione in corso'}</p>
                <p>{test_user.aiMessage}</p>
            </div>

            {/* Main Content */}
            <div className="main-content">
                <div className="section-container">
                    {/* Rende personal-timeline e shared-timelines nella stessa riga */}
                    <div className="timelines-row">

                        <div
                            className="section-card personal-timeline-box"
                            onClick={handleCardClick_PersonalTimeline} // Aggiungi l'evento onClick
                            style={{ cursor: 'pointer' }} // Indica che � cliccabile
                        >
                            <h3>Timeline Personale</h3>
                            <p>Scopri e ripercorri i momenti salienti della tua vita.</p>
                            <div className="stats-card">
                                <h4>Milestone</h4>
                                <p>8</p>
                            </div>
                        </div>

                        <div
                            className="section-card shared-timelines-box"
                            onClick={handleCardClick_SharedTimelines} // Aggiungi l'evento onClick
                            style={{ cursor: 'pointer' }} // Indica che � cliccabile
                        >
                            <h3>Timeline Collaborative</h3>
                            <p>Imposta e monitora i tuoi obiettivi di crescita personale.</p>
                            <div className="stats-card">
                                <h4>Milestone</h4>
                                <p>5</p>
                            </div>
                        </div>

                    </div>

                    {/* Card per gli obiettivi futuri */}
                    <div className="other-components-row">
                        <div className="section-card future-goals">
                            <h3>Obiettivi Futuri</h3>
                            <p>Imposta e monitora i tuoi obiettivi di crescita personale.</p>
                            <div className="stats-card">
                                <h4>Milestone</h4>
                                <p>3</p>
                            </div>
                        </div>

                        {/* Suggerimento AI */}
                        <div className="section-card suggestion-card">
                            <h3>Suggerimento AI</h3>
                            <p>
                                L'AI ti suggerisce di concentrarti sui tuoi obiettivi professionali per il prossimo mese. Prenditi anche del tempo per esplorare nuove possibilit&#224; personali.
                            </p>
                        </div>

                        {/* Statistiche */}
                        <div className="section-card statistics-card">
                            <h3>Statistiche</h3>
                            <p>
                                Scopri le tue statistiche.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;