import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchTimelines = (userId, trigger, token) => {
    const [timelines, setTimelines] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!userId) return;

        const fetchTimelines = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `https://api.onestories.life/user/${userId}/timelines`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    }
                );
                setTimelines(response.data);
            } catch (err) {
                setError('Unable to load timelines');
                console.error('Errore durante il caricamento delle timelines:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchTimelines();
    }, [userId, trigger, token]);

    return { timelines, loading, error };
};

export default useFetchTimelines;