import axios from 'axios';

const createPersonalTimeline = async (userId, userAccessToken) => {
    try {
        const response = await axios.post(
            `https://api.onestories.life/user/${userId}/personalTimeline`,
            {}, // Puoi aggiungere un corpo JSON se necessario
            {
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                },
            }
        );
        console.log("response.data.personalTimelineId: ", response.data.timelineId);
        return response.data.timelineId;
    } catch (error) {
        throw new Error('Errore nella creazione della timeline: ' + error.message);
    }
};

export default createPersonalTimeline;