import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import useFetchTimelines from '../../hooks/useFetchTimelines';
import fetchSharedTimelines from '../../hooks/fetchSharedTimelines';
import TimelinePreview from '../TimelinePreview/TimelinePreview';
import TimelineStatisticsPopup from '../TimelineSummaryCreationPopup/TimelineSummaryCreationPopup';
import ShareModal from '../ShareModal/ShareModal';
import './Timelines.css';

const Timelines = ({ userAccessToken, setPage, personalTimelineId }) => {
    const { accounts } = useMsal();
    const user = accounts.length > 0 ? accounts[0] : null;
    const userId = user ? user.localAccountId : null;

    const [trigger, setTrigger] = useState(0);
    const [selectedTimeline, setSelectedTimeline] = useState(null);
    const [sharedTimelines, setSharedTimelines] = useState([]);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true); // Imposta la sidebar come visibile all'inizio
    const [isCreateMilestonePopupOpen, setIsCreateMilestonePopupOpen] = useState(false);
    const [isStatisticsPopupOpen, setIsStatisticsPopupOpen] = useState(false); // Stato per il popup delle statistiche
    const [isSharePopupOpen, setIsSharePopupOpen] = useState(false); // Stato per il popup delle statistiche
    const [newTimelineData, setNewTimelineData] = useState({ title: '', description: '' });

    const { timelines, loading: timelinesLoading, error: timelinesError } = useFetchTimelines(
        userId,
        trigger,
        userAccessToken
    );

    useEffect(() => {
        // Imposta la visibilit� della sidebar al caricamento della pagina
        setIsSidebarVisible(true);
    }, []); // Esegui una sola volta al caricamento della pagina


    const handleSelectTimeline = async (timeline) => {
        setSelectedTimeline(timeline);

        // Su mobile (schermo < 768px), nascondi la sidebar quando si seleziona una timeline
        if (window.innerWidth < 768) {
            setIsSidebarVisible(false);
        }
    };

    const handleResize = () => {
        // Se la larghezza dello schermo � maggiore di 768px (desktop), mostra la sidebar
        if (window.innerWidth >= 768) {
            setIsSidebarVisible(true);
        }
    };

    // Aggiungi l'evento resize per aggiornare la visibilit� della sidebar su desktop
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Pulisci l'evento quando il componente � smontato
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Aggiungi un effetto solo quando il componente � montato

    const handleRefresh = async () => {
        setTrigger((prev) => prev + 1);

        try {
            const shared = await fetchSharedTimelines(userId, userAccessToken);
            setSharedTimelines(shared);
        } catch (error) {
            console.error('Errore nel recupero delle timeline condivise', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const shared = await fetchSharedTimelines(userId, userAccessToken);
                setSharedTimelines(shared);
            } catch (error) {
                console.error('Errore nel recupero delle timeline condivise', error);
            }
        };

        if (userId) {
            fetchData();
        }
    }, [userId, userAccessToken]);

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const toggleCreateMilestonePopup = () => {
        setIsCreateMilestonePopupOpen(!isCreateMilestonePopupOpen);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTimelineData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const createSharedTimeline = async () => {
        try {
            const response = await fetch('https://api.onestories.life/user/' + userId + '/sharedTimeline', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userAccessToken}`,
                },
                body: JSON.stringify(newTimelineData),
            });

            const data = await response.json();

            if (response.ok) {
                handleRefresh();
                toggleCreateMilestonePopup();
            } else {
                console.error('Errore nella creazione della timeline:', data.error);
            }
        } catch (error) {
            console.error('Errore durante la creazione della timeline', error);
        }
    };

    // Funzione per aprire il popup delle statistiche
    const handleOpenStatisticsPopup = (timeline) => {
        setSelectedTimeline(timeline);
        setIsStatisticsPopupOpen(true);
    };

    const handleOpenSharePopup = (timeline) => {
        setSelectedTimeline(timeline);
        setIsSharePopupOpen(true);
    };

    // Funzione per creare la milestone riassuntiva
    const handleCreateSummaryMilestone = (timeline) => {
        console.log('Creazione milestone riassuntiva per la timeline:', timeline);
        // Logica per creare la milestone riassuntiva
    };

    return (
        <div className="custom-timelines-container">
            <div className={`timeline-menu-button-container ${isSidebarVisible ? 'hidden' : 'visible'}`}>
                <button className="timeline-menu-button" onClick={toggleSidebar}>
                    Timeline
                </button>
            </div>
            <div className="timelines-layout">
                <div className={`timeline-list ${isSidebarVisible ? 'visible' : 'hidden'}`}>
                    <h3>Le mie Timeline</h3>
                    {timelinesLoading && <p className="custom-loading">Caricamento in corso...</p>}
                    {timelinesError && <p className="custom-error">{timelinesError}</p>}
                    {!timelinesLoading && timelines.length === 0 && <p>Non ci sono timeline disponibili.</p>}

                    <div className="custom-timelines-list">
                        {timelines &&
                            timelines.map((timeline) => (
                                <div key={timeline.id} className="custom-timeline-item">
                                    <div onClick={() => handleSelectTimeline(timeline)}>
                                        <h3>{timeline.title}</h3>
                                        <p>{timeline.description}</p>
                                        <p className="timelineMilestoneCount">{timeline.milestoneCount} milestones</p>
                                    </div>
                                    {/* Mostra il pulsante solo per la timeline selezionata */}
                                    {selectedTimeline && selectedTimeline.id === timeline.id && (
                                        <div className="button-container">
                                            <button
                                                className="share-button"
                                                onClick={() => handleOpenSharePopup(timeline)} // Apre il popup delle statistiche
                                            >
                                                Condividi
                                            </button>
                                            <button
                                                className="create-summary-button"
                                                onClick={() => handleOpenStatisticsPopup(timeline)} // Apre il popup delle statistiche
                                            >
                                                Aggiungi a Timeline personale
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>

                    <h3>Condivise con Me</h3>
                    {sharedTimelines.length === 0 && <p>Non ci sono timelines condivise.</p>}
                    {sharedTimelines.length > 0 && (
                        <div className={`custom-timelines-list ${sharedTimelines.length > 0 ? 'visible' : ''}`}>
                            {sharedTimelines.map((timeline) => (
                                <div
                                    key={timeline.id}
                                    className="custom-timeline-item"
                                    onClick={() => handleSelectTimeline(timeline)}
                                >
                                    <h3>{timeline.title}</h3>
                                    <p>{timeline.description}</p>
                                </div>
                            ))}
                        </div>
                    )}

                    <button onClick={toggleCreateMilestonePopup} className="custom-add-button">
                        Crea Timeline
                    </button>

                    {isCreateMilestonePopupOpen && (
                        <div className="create-timeline-popup-overlay">
                            <div className="create-timeline-popup">
                                <h3>Crea una Nuova Timeline</h3>
                                <label htmlFor="title">Titolo:</label>
                                <input
                                    type="text"
                                    id="title"
                                    name="title"
                                    value={newTimelineData.title}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="description">Descrizione:</label>
                                <textarea
                                    id="description"
                                    name="description"
                                    value={newTimelineData.description}
                                    onChange={handleInputChange}
                                    required
                                ></textarea>
                                <button onClick={createSharedTimeline}>Crea</button>
                                <button onClick={toggleCreateMilestonePopup}>Annulla</button>
                            </div>
                        </div>
                    )}

                    <button onClick={handleRefresh} className="custom-refresh-button">
                        &#10227;
                    </button>
                </div>

                <div className="timeline-preview-timelines">
                    {selectedTimeline && (
                        <TimelinePreview
                            userAccessToken={userAccessToken}
                            userId={user.localAccountId}
                            timelineId={selectedTimeline.id}
                        />
                    )}
                </div>
            </div>

            {/* Popup delle statistiche */}
            {isStatisticsPopupOpen && (
                <TimelineStatisticsPopup
                    userAccessToken={userAccessToken}
                    timeline={selectedTimeline}
                    personalTimelineId={personalTimelineId}
                    onClose={() => setIsStatisticsPopupOpen(false)}
                    onCreateSummaryMilestone={handleCreateSummaryMilestone}
                />
            )}

            {/* Popup della condivisione */}
            {isSharePopupOpen && (
                <ShareModal
                    userAccessToken={userAccessToken}
                    userId={userId}
                    timelineId={selectedTimeline.id}
                    onClose={() => setIsSharePopupOpen(false)}
                />
            )}
        </div>
    );
};

export default Timelines;