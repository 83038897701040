import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import CreateMilestone from '../CreateMilestone/CreateMilestone'; // Assicurati di importare CreateMilestone
import './TimelineSummaryCreationPopup.css';

const TimelineSummaryCreationPopup = ({ userAccessToken, timeline, personalTimelineId, onClose }) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [error, setError] = useState(null);
    const [generatedSummaryMilestone, setGeneratedSummaryMilestone] = useState(null);

    const { accounts } = useMsal();
    const user = accounts.length > 0 ? accounts[0] : null;
    const userId = user ? user.localAccountId : null;

    if (!timeline) return null;

    // Verifica la presenza di personalTimelineId e mostra un banner di errore se non presente
    if (!personalTimelineId) {
        return (
            <div className="popup-overlay">
                <div className="popup-content">
                    <h3>Aggiungi a Timeline personale</h3>
                    <div className="error-banner">
                        <p>Timeline personale non trovata. Per procedere, crea la tua Timeline personale.</p>
                    </div>
                    <button onClick={onClose}>Chiudi</button>
                </div>
            </div>
        );
    }

    const handleCreateSummaryMilestone = () => {
        setIsGenerating(true);
        setError(null);

        // Chiamata API per generare la milestone riassuntiva
        fetch('https://api.onestories.life/user/' + userId + '/timeline/' + timeline.id + '/generateSummaryMilestone', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userAccessToken}`, },
            body: JSON.stringify({ timelineId: timeline.id }),
        })
            .then((res) => res.json())
            .then((data) => {
                setGeneratedSummaryMilestone(data); // Salva la milestone generata
            })
            .catch((err) => {
                console.error('Errore nella creazione della milestone:', err);
                setError('Si � verificato un errore durante la generazione della milestone.');
            })
            .finally(() => {
                setIsGenerating(false);
            });
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <h3>Aggiungi a Timeline personale</h3>
                <p className="createSummaryMilestone_description">Aggiungi questa Timeline alla tua storia personale, con una Milestone riassuntiva generata dall'AI.</p>
                <p className="createSummaryMilestone_list"><strong>Nome:</strong> {timeline.title}</p>
                <p className="createSummaryMilestone_list"><strong>Descrizione:</strong> {timeline.description}</p>
                <p className="createSummaryMilestone_list"><strong>Numero di Milestone:</strong> {timeline.milestoneCount || 0}</p>
                <p className="createSummaryMilestone_list"><strong>Durata:</strong> {timeline.duration || 'Non disponibile'}</p>
                {error && <p className="error">{error}</p>}
                {generatedSummaryMilestone ? (
                    <CreateMilestone
                        userAccessToken={userAccessToken}
                        timelineId={personalTimelineId}
                        onSave={(milestone) => {
                            onClose(); // Chiudi il popup dopo aver salvato
                        }}
                        generatedSummaryMilestone={generatedSummaryMilestone}
                        initialCreationMethod="manual"
                        summaryTimelineId={timeline.id}
                    />
                ) : (
                    <div className="popup-buttons">
                        <button onClick={handleCreateSummaryMilestone} disabled={isGenerating}>
                            {isGenerating ? 'Creazione in corso...' : 'Crea Milestone Riassuntiva'}
                        </button>
                        <button onClick={onClose}>Annulla</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TimelineSummaryCreationPopup;