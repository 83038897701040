import React from 'react';
import './FloatingButtonRefresh.css';

const FloatingButtonRefresh = ({ onClick }) => {
  return (
    <button className="floating-button-refresh" onClick={onClick}>
        &#10227;
    </button>
  );
};

export default FloatingButtonRefresh