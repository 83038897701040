import React, { useState, useEffect } from 'react';
import TimelinePreview from '../TimelinePreview/TimelinePreview';
import MissingTimelineCard from '../MissingTimelineCard/MissingTimelineCard';
import fetchPersonalTimeline from '../../hooks/fetchPersonalTimeline';
import './PersonalTimeline.css';

function PersonalTimeline({ user, personalTimelineId, setPersonalTimelineId }) {

    const [showPopup, setShowPopup] = useState(false);
    const [expandedTimeline, setExpandedTimeline] = useState(false);

    useEffect(() => {
        if (user.accessToken) {
            (async () => {
                try {
                    const timelineId = await fetchPersonalTimeline(user.localAccountId, user.accessToken);
                    if (timelineId) {
                        setPersonalTimelineId(timelineId);
                    } else {
                        setPersonalTimelineId(null);
                        setShowPopup(true);
                    }
                } catch (error) {
                    console.error('Errore nella fetch della timeline personale:', error.message);
                    setPersonalTimelineId(null);
                    setShowPopup(true);
                }
            })();
        } else {
            // Resetta lo stato se il fetch non pu� avvenire
            setPersonalTimelineId(null);
            setShowPopup(false);
        }
    }, [user.accessToken]);

    const handleTimelineCreated = (timelineId) => {
        setPersonalTimelineId(timelineId);
        setShowPopup(false);
    };

    if (personalTimelineId) {
        return (
            <div className="personal-timeline-page">
                <div className="personal-timeline">
                    <TimelinePreview
                        userAccessToken={user.accessToken}
                        userId={user.localAccountId}
                        timelineId={personalTimelineId}
                        setExpandedTimeline={setExpandedTimeline}
                    />
                </div>
                {expandedTimeline && (
                    <div className="expanded-timeline-popup-overlay">
                        <div className="expanded-timeline-popup">
                            <div className="expanded-timeline-header">
                                <button
                                    className="close-button"
                                    onClick={(e) => {
                                        setExpandedTimeline(null);
                                    }}
                                >
                                    &#10005;
                                </button>
                            </div>
                            <TimelinePreview
                                userAccessToken={user.accessToken}
                                userId={user.localAccountId}
                                timelineId={expandedTimeline}
                                expandedTimeline={expandedTimeline}
                            />
                        </div>
                    </div>
                )}                
            </div>
        );
    } else {
        return (
            showPopup && (
                <MissingTimelineCard
                    userId={user.localAccountId}
                    userAccessToken={user.accessToken}
                    onTimelineCreated={handleTimelineCreated}
                />
            )
        );
    }
}

export default PersonalTimeline;