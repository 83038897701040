import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import useShareShareWithUser from './useShareShareWithUser';
import shareCreateLink from './shareCreateLink';
import './ShareModal.css';

const ShareModal = ({ userAccessToken, userId, timelineId }) => {

    const [shareMethod, setShareMethod] = useState();
    const [userToShare, setUserToShare] = useState();
    const [shareToken, setShareToken] = useState();
    const [shareURL, setShareURL] = useState();
    
    useEffect(() => {
        
    }, []);

    const handleGenerateShareLink = async (timelineId) => {
        try {
            const token = await shareCreateLink(userAccessToken, userId, timelineId);
            setShareToken(token); // Imposta il valore del link una volta risolto
            setShareURL("https://app.onestories.life/timeline/view/" + token);
        } catch (error) {
            console.error('Errore durante la generazione del link:', error);
        }
    };

    const copyToClipboard = () => {
        if (shareURL) {
            navigator.clipboard.writeText(shareURL);
        }
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <h2>Condividi Timeline</h2>

                {/* Metodo di condivisione */}
                <div className="share-method">
                    <button
                        className={shareMethod === 'user' ? 'active' : ''}
                        onClick={() => setShareMethod('user')}
                    >
                        Condividi con Utente
                    </button>
                    <button
                        className={shareMethod === 'link' ? 'active' : ''}
                        onClick={() => setShareMethod('link')}
                    >
                        Crea Link
                    </button>
                </div>

                {/* Form per condivisione con Utente */}
                {shareMethod === 'user' && (
                    <form>
                        <div className="input-group">
                            <label htmlFor="title">Titolo</label>
                            <input
                                id="userToShare"
                                type="text"
                                value={userToShare}
                                onChange={(e) => setUserToShare(e.target.value)}
                                required
                            />
                        </div>
                        <button type="button" onClick={useShareShareWithUser}>
                            Condividi
                        </button>
                    </form>
                )}

                {/* Form per condivisione con Link */}
                {shareMethod === 'link' && (
                    <form>
                        <button type="button" onClick={() => handleGenerateShareLink(timelineId)}>
                            Crea Link
                        </button>
                    </form> 
                )}


                {shareToken && (
                    <div className="share-token-container">
                        <input
                            type="text"
                            value={shareURL}
                            readOnly
                            id="shareTokenInput"
                            className="share-token-input"
                        />
                        <button
                            className="copy-button"
                            onClick={copyToClipboard}
                        >
                            Copia
                        </button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default ShareModal;