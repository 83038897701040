import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

const useAuthentication = (setUser, setError) => {
    const { instance, accounts, inProgress } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && inProgress === 'none') {
            const account = accounts[0];
            instance
                .acquireTokenSilent({
                    account: account,
                    scopes: ['openid', 'profile', 'email', 'https://onestoriesb2c.onmicrosoft.com/milestones-api/milestones.read'],
                })
                .then((response) => {
                    const email =
                        response.idTokenClaims.preferred_username ||
                        response.idTokenClaims.email ||
                        response.idTokenClaims.username;
                    const accessToken = response.accessToken;  // Aggiungi l'accessToken
                    setUser({ ...account, email, accessToken }); // Memorizza anche l'accessToken
                })
                .catch((err) => {
                    console.error('Errore durante il token silent:', err);
                    setError('Authentication error');
                });
        };
    }, [accounts, inProgress, instance, setUser, setError]);

    const login = () => {
        // Avvia il flusso di login
        instance.loginRedirect().catch((error) => {
            console.error('Login failed', error);
            setError('Login failed');
        });
    };

    const logout = () => {
        // Esegui il logout
        instance.logoutRedirect().catch((error) => {
            console.error('Logout failed', error);
            setError('Logout failed');
        });
        setUser(null); // Reset dell'utente
    };

    return {
        instance,
        accounts,
        inProgress,
        login,
        logout,
    };
};

export default useAuthentication;