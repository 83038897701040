import axios from 'axios';

const fetchSharedTimelines = async (userId, userAccessToken) => {
    try {
        const response = await axios.get(`https://api.onestories.life/user/${userId}/sharedTimelines`, {
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
            },
        });
        return response.data; // Restituisce le timeline condivise
    } catch (error) {
        throw new Error('Errore nel recupero delle timeline condivise: ' + error.message);
    }
};

export default fetchSharedTimelines;